@import "./variables";

.table {
    margin-top: 10px;
    border: 2px solid $corporativo;
    border-radius: 10px;
    width: 100%;
    thead {
        color: white;
        background-color: $corporativo;
    }
}

.btn-primary {
    background-color: $corporativo !important;
    border-color: $corporativo !important;
}

.alert {
    .close {
        background-color: transparent;
        border: none;
        font-size: 20px;
    }
}

.ant-modal-close {
    display: none;
}

.bg-crh {
    background-color: $corporativo !important;
    color: white !important;
}

.input {
    position: relative;
    width: 100%;

    &__label {
        position: absolute;
        padding: 0 5px;
        left: 10px;
        top: 10px;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background: white;
        transition: transform 120ms ease-in;
        line-height: 1.2;
        cursor: text;
        font-weight: 500 !important;
        font-size: medium !important;
    }

    &__field {
        font-weight: 200 !important;
        font-size: medium !important;
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 10px 15px;
        background: transparent;
        &:focus, &:not(:placeholder-shown) {
            & + .input__label {
                transform: translate(.30rem, -120%) scale(.85);
                color: $corporativo;
            }
        }

    }


}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover { 
    cursor:pointer; 
}

.report-header {
    display: none;
}

.report-content {
    overflow: auto;
    max-height: 60vh;
}

@media print {

    .report-content {
        overflow: hidden !important;
        overflow-y: hidden !important;
        width: 100%;
        height: 100%;
        max-height: fit-content;
    }

    .report-actions {
        display: none;
    }

    .report-header {
        display: block;
        margin-top: 40%;
    }

}

@media print {
    html, body {
        zoom: 0.75;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;
    }
}

@page {
    @bottom-right {
        content: counter(page) ' de ' counter(pages);
    }
}

@page {
    size: auto;
    margin: 5mm;
}

@media all {
    .pagebreak {
        display: none; 
    }
    .page-divider {
        display: block; 
    } 
}

@media print {
    .add-incidence-request {
        display: none;
    }
    .pagebreak {
        display: block;
        page-break-before: always; 
    }
    .page-divider {
        display: none; 
    } 
    .report-hidden {
        display: none;
    }
    .attendance-report {
        zoom: 1.25 !important;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden !important;
        overflow-y: hidden !important;
        width: 100% !important;
    }
}